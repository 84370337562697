<template>
  <vue-final-modal class="modal-pwa-qr">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getLabel('title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('pwa-qr')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="qr__content">
        <div class="qr__header">
          {{ getLabel('header') }}
        </div>
        <qr-code :qr-address="getLabel('qrUrl')" :width="220" />
        <div class="qr__description">
          {{ getLabel('description') }}
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';
  import QrCode from '~/components/atomic/qr-code.vue';

  const props = defineProps<{
    currentLocaleData?: Maybe<CIModalsContent['pwaQr']>;
    defaultLocaleData?: Maybe<CIModalsContent['pwaQr']>;
  }>();
  const { getContent } = useProjectMethods();

  const getLabel = (path: string): string => getContent(props.currentLocaleData, props.defaultLocaleData, path);

  const { closeModal } = useModalStore();
</script>
<style lang="scss" src="~/assets/styles/components/modal/pwa-qr.scss" />
